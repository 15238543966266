import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as forOurApplicantsStyles from "./for-our-applicants.module.scss"

const ForOurApplicants = ({ pageContext, location }) => {
  const pageTitle = "For Our Applicants"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h1>Applicant Resources</h1>
        
        <ul className={forOurApplicantsStyles.list}>
          <li>
            <a
              href="../../docs/applicant-resources/IER_RightToWorkPoster Eng_Es.pdf"
              title="Right to Work Flyer"
              className="biggerLink"
              target="_blank"
            >
              Right to Work Flyer
            </a>
          </li>
          <li>
            <a
              href="../../docs/applicant-resources/federal-e-verify-participation-poster-es.pdf"
              title="E-Verify Flyer"
              className="biggerLink"
              target="_blank"
            >
              E-Verify Flyer
            </a>
          </li>
        </ul>
        

        <p><a class="CTAblack" href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst=" title="View Open Positions">View Open Positions</a></p>
      </div>
    </Layout>
  )
}

export default ForOurApplicants
